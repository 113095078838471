body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* EDITOR */
.editor {

  margin: 20px 0px;

}

.text-result {

  visibility: hidden;

}

.visible {

  visibility: visible;

}

/* NEWS FORM */
.news-form label {

  font-weight: bolder;

}